<ems-expense-form-layout>
  <div [formGroup]="form" content>
    <no-match-alert
      *ngIf="expense?.paymentType === 'user'"
    ></no-match-alert>
    <sis-credit-card-holder-alert
      *ngIf="expense?.paymentType === 'personal' && (creditCardUser$ | async)"
    ></sis-credit-card-holder-alert>
    <sis-expense-mismatch-alert
      *ngIf="isOcrMismatch"
    ></sis-expense-mismatch-alert>

    <ems-base-stack>
      <div
        class="expense-form-edit"
        [attr.alert-present]="expense?.ocrMismatch || (creditCardUser$ | async)"
      >
        <!-- left column -->
        <div>
          <!-- Vendor -->
          <sis-form-control
            label="Vendor"
            inputId="vendor"
            [class.input-error]="vendorIsRequired"
          >
            <input
              formControlName="vendor"
              placeholder="Ex. Trader Joe's"
              id="vendor"
              [value]="expense?.vendor"
              [class.input-error]="vendorIsRequired"
              [maxlength]="maxLength"
              [attr.disabled]="expense?.cardVerified === true ? '' : isTrashed"
            />
          </sis-form-control>
          <ems-base-helper-text
            [style.visibility]="vendorIsRequired ? 'visible' : 'hidden'"
            variation="negative"
          >
            Vendor is required.
          </ems-base-helper-text>

          <!-- Address -->
          <sis-form-control
            class="bottom-margin"
            label="Address (optional)"
            inputId="address"
          >
            <textarea
              formControlName="address"
              type="text"
              class="form-control-textarea"
              placeholder="Ex. 1916 Anne Ave N. Seattle, WA 98109"
              id="address"
              [value]="expense?.address"
              [maxlength]="maxLength"
              [attr.disabled]="
                expense?.cardVerified === true ? '' : null || isTrashed
              "
            ></textarea>
          </sis-form-control>

          <!-- Transaction Date -->
          <sis-form-control
            [ngClass]="dateIsRequired ? 'is-invalid' : ''"
            label="Transaction Date"
            inputId="transaction-date"
            [class.input-error]="inputError"
          >
            <ems-datepicker
              inputId="transaction-date"
              formControlName="transactionDate"
              [maxDate]="maxDate"
              [isDisabled]="expense?.cardVerified === true || isTrashed"
              (dateChanged)="OnValueChange($event,'date')"
            ></ems-datepicker>
          </sis-form-control>
          <ems-base-helper-text
            [style.visibility]="dateIsRequired ? 'visible' : 'hidden'"
            variation="negative"
          >
            Date is required.
          </ems-base-helper-text>

          <!-- Amount -->
          <sis-form-control
            label="Amount"
            inputId="edit-expense-amount"
            [class.input-error]="inputError"
          >
            <input
              formControlName="amount"
              placeholder="Ex. 11.97"
              type="text"
              id="edit-expense-amount"
              [attr.disabled]="
                expense?.cardVerified || expense?.parent ? '' : isTrashed
              "
              (ngModelChange)="OnValueChange($event,'amount')"
            />
          </sis-form-control>
          <ems-base-helper-text
            [style.visibility]="
              amountIsInvalid ||
              amountIsRequired ||
              amountRequiredForItemization ||
              maxAmountExceeded
                ? 'visible'
                : 'hidden'
            "
            variation="negative"
          >
            {{
              amountIsInvalid
                ? 'Please enter a valid amount.'
                : amountIsRequired
                ? 'Amount is required.'
                : amountRequiredForItemization
                ? ' Amount is required to itemize expense.'
                : maxAmountExceeded
                ? 'Amount must be less than 10 million dollars.'
                : ''
            }}&nbsp;
          </ems-base-helper-text>
        </div>

        <!-- right column -->
        <div>
          <!-- Expense Type -->
          <sis-form-control
            label="Expense Type"
            inputId="expense-type"
            [class.input-error]="expenseTypeIsRequired"
          >
            <sis-select
              inputId="expense-type"
              [options]="expenseTypes"
              valueKey="id"
              textKey="name"
              titleKey="description"
              [isDisabled]="isTrashed"
              formControlName="type"
            ></sis-select>
          </sis-form-control>
          <ems-base-helper-text
            [style.visibility]="expenseTypeIsRequired ? 'visible' : 'hidden'"
            variation="negative"
          >
            Expense Type is required
          </ems-base-helper-text>

          <!-- Description -->
          <sis-form-control
            label="Description"
            inputId="description"
            [class.input-error]="descriptionIsRequired"
          >
            <textarea
              formControlName="description"
              type="text"
              class="form-control-textarea"
              id="description"
              placeholder="Example: Taxi from airport to condo."
              [value]="expense?.description"
              [attr.disabled]="isTrashed"
              [maxlength]="maxLength"
            ></textarea>
          </sis-form-control>
          <ems-base-helper-text
            [style.visibility]="descriptionIsRequired ? 'visible' : 'hidden'"
            variation="negative"
          >
            Description is required
          </ems-base-helper-text>

          <!-- Job Code Autocomplete -->
          <ems-typeahead
            type="jobCode"
            label="Job Code"
            [items]="jobCodes"
            [favoritable]="true"
            [favorites]="favoriteJobCodes"
            (favorite)="onFavorite($event)"
            formControlName="jobCode"
            [isDisabled]="isTrashed"
            [isInvalid]="jobCodeIsRequired"
            [canClear]="true"
            [class.input-error]="jobCodeIsRequired"
          ></ems-typeahead>
          <ems-base-helper-text
            [style.visibility]="jobCodeIsRequired ? 'visible' : 'hidden'"
            variation="negative"
          >
            Job Code is required
          </ems-base-helper-text>

          <!-- Fraud -->
          <div
            *ngIf="showFraud"
            [class.error]="fraudulentInvalid"
            class="fraudulent-container"
          >
            <label class="ems-label fraudulent-label">Fraud</label>
            <label style="display: contents">
              <div class="fraudulent-checkbox ems-checkbox">
                <input type="checkbox" formControlName="fraudulent" />
                <div class="ems-checkbox__indicator"></div>
              </div>
              <span class="fraudulent-text">
                Notified Credit Card Company and/or Vendor?
                <br />
                Credit card customer service number is on the back of your
                credit card.
              </span>
            </label>
          </div>

          <!-- Custom Case -->
          <ems-typeahead
            #salesforceEl
            label="Custom Case"
            type="salesforceCase"
            inputId="custom-case"
            [items]="salesforceCases"
            [favorites]="favoriteSalesforceCases"
            formControlName="salesforceId"
            (favorite)="onFavoriteSalesforceCase($event)"
            [isDisabled]="isTrashed"
            [canClear]="true"
          ></ems-typeahead>
        </div>
      </div>
      <!-- Travel Ticket -->
      <travel-ticket-view
        *ngIf="canAssignTDR$ | async"
        [formId]="formId"
        [expense]="expense"
        [expenseTypes]="expenseTypes"
        (travelTicketAssigned)="onTravelTicketAction($event)"
        (travelTicketUnassigned)="onTravelTicketAction($event)"
        class="bottom-margin"
      ></travel-ticket-view>
      <!-- Billable -->
      <sis-form-control label="Billable" *ngIf="showBillable">
        <sis-two-radio-buttons
          [displayTexts]="['Yes', 'No']"
          [values]="[true, false]"
          [disabled]="isTrashed"
          formControlName="isBillable"
        ></sis-two-radio-buttons>
      </sis-form-control>

      <!-- Notes -->
      <div class="ems-field add-note">
        <ems-base-inline *ngIf="!showNote">
          <ems-base-icon
            iconName="add-small"
            (click)="onToggleNote()"
            class="note-icon add"
            [class.disabled]="isTrashed"
          ></ems-base-icon>
          <span class="ems-label ems-label--expense-note"> Add Note </span>
        </ems-base-inline>
        <ems-base-inline *ngIf="showNote">
          <ems-base-icon
            iconName="add-small"
            (click)="onToggleNote()"
            class="note-icon remove"
            [class.disabled]="isTrashed"
          ></ems-base-icon>
          <span class="ems-label ems-label--expense-note"> Note </span>
        </ems-base-inline>
        <div class="ems-field" *ngIf="showNote">
          <div class="ems-control">
            <textarea
              formControlName="message"
              placeholder=""
              class="ems-textarea"
              [value]="expense?.message ? expense?.message : ''"
              [attr.disabled]="isTrashed"
            ></textarea>
          </div>
        </div>
      </div>

      <expense-match
        *ngIf="expense?.cardVerified === true"
        (onMatchExpense)="onMatchExpense($event)"
      ></expense-match>

      <div
        *ngIf="(expense?.extraInfo | keyvalue)?.length"
        class="additional-details"
      >
        <hr />
        <div>
          <span>Additional Details</span>
          <ems-base-icon
            (click)="onAdditionalDetailsClick()"
            [ngClass]="showAdditionalDetails ? 'rotated' : ''"
            iconName="accordian_carrot"
            class="additional-details__icon"
          >
          </ems-base-icon>
        </div>
        <ems-details-card
          *ngIf="
            (expense?.extraInfo | keyvalue)?.length && showAdditionalDetails
          "
          [detailsData]="expense?.extraInfo"
        ></ems-details-card>
        <hr />
      </div>
      <div
        class="ems-field travel-ticket-config"
        *ngIf="!isTrashed && ((canAssignTDR$ | async) || expense.travelTicket)"
      ></div>
    </ems-base-stack>
  </div>

  <receipt-container
    receipt
    [imageUrl]="expense?.image"
    [hasReceipt]="expense?.hasReceipt"
    (fileSelected)="onFileSelected($event)"
    (removeReceipt)="onRemoveReceipt()"
  ></receipt-container>
</ems-expense-form-layout>

<!-- Additional Details-->
<ems-base-tab
  [tabTitle]="isMobileView ? 'Details' : 'Additional Details'"
  *ngIf="(expense?.extraInfo | keyvalue)?.length && false"
>
  <ems-details-card [detailsData]="expense?.extraInfo"></ems-details-card>
</ems-base-tab>

<!-- Activity Log -->
<ems-base-tab
  [tabTitle]="isMobileView ? 'Activity' : 'Activity Log'"
  *ngIf="expense?.status === 'rejected'"
>
  <ems-base-stack space="l">
    <ems-base-box *ngFor="let item of expense?.activity">
      <ems-expense-note
        [noteTitle]="item.action"
        [date]="getTimestamp(item.date)"
        [content]="item.message ? item.message : ''"
        [user]="item.author"
        [email]="item.author?.work_email"
        [id]="item.author?.employee_number"
      ></ems-expense-note>
    </ems-base-box>
  </ems-base-stack>
</ems-base-tab>

<!-- Travel Ticket -->
<!-- <ems-base-tab
  [tabTitle]="'Travel Ticket'"
  *ngIf="
  (!isTrashed && (
    (!(proxyId$ | async) && canAssignTDR$ | async) ||
    ((proxyId$ | async) && proxyCanAssignTDR$ | async) ||
    expense.travelTicket)
  )"
>
</ems-base-tab> -->
