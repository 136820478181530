import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  State,
  ExpenseMatchActions,
  selectUserCreatedCreditCardExpenses,
  WebSettingsFacade,
} from '@ems-gui/expense/util-web-ngrx';
import { Expense, JobCode } from '@ems-gui/shared/util-core';

/**
 * Component will show the user their User Created Credit Card expenses,
 * and allow them to select one to match to the current System Created Credit Card expense.
 *
 * Once matched, all the details from the User Created Credit Card expense will be copied
 * over to the empty System Created Credit Card expense fields.
 */

@Component({
  selector: 'expense-match',
  templateUrl: './expense-match.component.html',
  styleUrls: ['./expense-match.component.scss'],
})
export class ExpenseMatchComponent implements OnInit {
  @Output() public onMatchExpense: EventEmitter<number> = new EventEmitter();
  isTableVisible: boolean = false;

  toggleTable() {
    this.isTableVisible = !this.isTableVisible;
  }

  public userCreatedCreditCardExpenses$ = this.store$.select(
    selectUserCreatedCreditCardExpenses
  );

  displayedColumns: string[] = [
    'date',
    'vendor',
    'amount',
    'jobCode',
    'description',
    'assign',
  ];
  displayedData: Expense[] = [];
  jobCodes: JobCode[] = [];

  constructor(
    private store$: Store<State>,
    public settingsState: WebSettingsFacade
  ) {}

  ngOnInit() {
    this.store$.dispatch(
      ExpenseMatchActions.requestUserCreatedCreditCardExpenses()
    );
    this.settingsState.jobCodes$.subscribe((jobCodes) => {
      this.jobCodes = jobCodes;
    });
    this.userCreatedCreditCardExpenses$.subscribe((expenses) => {
      this.displayedData = expenses;
      this.displayedData.forEach((expense) => {
        const jobCode = this.jobCodes.find(
          (jobCode) => jobCode.id === expense.jobCode
        );
        if(!jobCode) return;
        expense.jobCode = +jobCode.code;
      });
    });
  }

  assignExpense(expense: Expense) {
    this.store$.dispatch(
      ExpenseMatchActions.matchExpense({ expenseId: expense.id })
    );
    this.onMatchExpense.emit(expense.id);
  }
}
